import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useRadio } from "./hooks";


export default function Concerts() {
  const { data,dataConcerts,dataConcertPr,setDataConcertPr,getConcerts } = useMyData();

  const pp = 20;
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [pageConcertPrs, setPageConcertPrs] = useState([]);

  const [tab_itemProps] = useRadio("jp"); // タブ


  useEffect(() => {
    if(dataConcerts && dataConcerts.concertprs && dataConcerts.concertprs.length > 0 ) {
      const thisConcertPrs = dataConcerts.concertprs.slice((pp * (page - 1)), (pp * page));
      setPageConcertPrs([...thisConcertPrs])
      let pagesCalc = [];
      let i=1;
      while(1){
        pagesCalc.push(i);
        if(i * pp >= dataConcerts.concertprs.length) {
          break;
        }
        i++;
      }
      setPages(pagesCalc);
    }
  }, [dataConcerts.concertprs,page]);


  useEffect(()=>{
    getConcerts();
    setDataConcertPr( {
    });
    window.scrollTo({
      top: 0,
      //behavior: "smooth",
    });
  },[data]);

  const convertDate = (date) => {
    const d = new Date(date);
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日';
  }
  const convertDateTime = (datetime) => {
    const dt = datetime.split(' ');
    const d = new Date(dt[0]);
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日 ' + dt[1].substr(0,5);
  }
  const convert2keta = (num) => {
    if(num < 10) {
      num = '0' + num;
    }
    return num;
  }
  const movePage = (e) => {
    console.log(e.currentTarget.dataset.page);
    setPage(e.currentTarget.dataset.page);

  }

  return (
    <>
      <h1 className="pagetopLabel">公演情報一覧</h1><br />


  <div className="tabs">
    <input id="jp" type="radio" name="tab_item" {...tab_itemProps} value="jp" checked={tab_itemProps.val=="jp"} /><label className="tab_item" htmlFor="jp">公演情報登録</label>
    <input id="eng" type="radio" name="tab_item" {...tab_itemProps} value="eng" checked={tab_itemProps.val=="eng"} /><label className="tab_item" htmlFor="eng">登録済み公演情報</label>

    <div className="tab_content" id="jp_content"><br />

      <p>下記より公演日を選択して該当する公演の情報を入力してください。<br />※公演が同日に複数回ある場合は、公演毎に情報を入力してください。</p>
      <h5>ご提供いただいた情報は次の目的に利用いたします。</h5>
      <ul class="disc">
        <li>公演当日のホールスタッフ配置時の参考資料として利用します。</li>
        <li>横浜みなとみらいホールの紙面版及びウェブサイト版コンサートカレンダーに公演情報を掲載します（任意）。<br />※提出された内容は、公益財団法人横浜市芸術文化振興財団が運営するwebサイト「ヨコハマ・アートナビ」にも掲載されます。<br />
        また、オープンデータ化して公開・活用する場合がございます。ただし掲載の有無は各情報媒体の判断によります。</li>
        <li>お客様からお問合せがあった場合に情報提供します。</li>
      </ul><br />

      <ul className="s-concertlist">
      {dataConcerts && dataConcerts.concerts && dataConcerts.concerts.map((concert) => (
        <li key={concert.id}>
          <Link to={"/concert/" + concert.v4 + "/add"}>
          {concert.type==0 ? <div className="tag-recommend">主催共催公演</div>  : ''}
          {concert.type==1 ? <div className="tag-cooperate">協力公演</div>  : ''}
          {concert.type==2 ? <div className="tag-rental">貸館公演</div>  : ''}

          {concert.venue==10 ? '大ホール ' : ''}
          {concert.venue==20 ? '小ホール ' : ''}
          {concert.venue==30 ? 'レセプションルーム ' : ''}
          {concert.venue==99 ? 'その他 ' : ''}

          ( {convertDate(concert.sdate)} )
          の公演情報登録
          </Link>
        </li>
      ))}
      </ul>

      <p>上記に該当する公演日の表示がない場合は、こちらから新規登録を行ってください。<br />※公演日が同日に複数回ある場合は、公演ごとに情報を入力してください。</p>

      <ul className="s-concertlist">
        <li className="link-rental">
          <Link to={"/concert/new"}>
            公演情報登録
          </Link>
        </li>
      </ul>

    </div>

    <div className="tab_content" id="eng_content">
      <h5>登録済みの公演情報一覧</h5>
      <p>ご登録いただいた公演情報はこちらからご確認いただけます。</p><br />
      <ul class="disc">
        <li>登録した情報に変更が生じたとき</li>
        <li>登録から7営業日を過ぎてもウェブサイト版コンサートカレンダーへの掲載がないとき<br />（紙面版コンサートカレンダーへの掲載については担当者より確認の連絡いたしますのでそれまでお待ちください。）</li>
      </ul>

      <p>以上の場合、横浜みなとみらいホール広報担当までご連絡ください。<br />
      <span class="linkArrow"><a title="" href="mailto:mmh@yaf.or.jp">メールを送る</a></span><br />
      電話：045-682-2020</p><br />

      {dataConcerts && dataConcerts.concertprs && dataConcerts.concertprs.length > 0 &&
      <>
        <ul className="s-concertlist">
          {pageConcertPrs.map((item) => (
            <li key={item.id}>
              <Link to={"/concertpr/" + item.v4}>
              {item.type==0 ? <div className="tag-recommend">主催共催公演</div>  : ''}
              {item.type==1 ? <div className="tag-cooperate">協力公演</div>  : ''}
              {item.type==2 ? <div className="tag-rental">貸館公演</div>  : ''}

              {item.venue==10 ? '大ホール ' : ''}
              {item.venue==20 ? '小ホール ' : ''}
              {item.venue==30 ? 'レセプションルーム ' : ''}
              {item.venue==99 ? 'その他 ' : ''}

              ( {convertDate(item.sdate)} )
              の公演情報 ( {convertDateTime(item.cdatetime)} 更新 )

              </Link>
            </li>
          ))}
        </ul>

        <ul className="s-concertlist pager">
          {pages.length > 1 && <>
            {page > 1 &&
              <li className="prev">
                <a data-page={(page - 1)} onClick={movePage}>««</a>
              </li>
            }
            {pages.map((item) => (
              <li key={item}>
                { (item == page) ?
                <span>{item}</span>
                :
                <a data-page={item} onClick={movePage}>{item}</a>
                }
              </li>
            ))}
            {[...pages].reverse()[0] != page &&
              <li className="next">
                <a data-page={(1 + parseInt(page))} onClick={movePage}>»»</a>
              </li>
            }
          </>}
        </ul>
      </>}

    </div>
  </div>


      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to='/' title="https://mypage.yokohama-minatomiraihall.jp/">戻る</Link>
        </div>
      </div>
    </>
 );
}
